//
// A collection of helper functions and classes
//

import {
  IAsset,
  IAssetCreationData,
  IAssetDeletionResult,
  IAssetCreationResult,
  IAssetDeletionData
} from '@/scripts/models';
import { getApi } from '@/scripts/apiHelper.ts';

// API calls
export class ApiAsset {
  public static apiBase = 'assets';

  // Get assets for profile/Searchid
  public static async getAssetsBySearchIdandType(searchId: string, assetType: string): Promise<IAsset[]> {
    const url = `get?searchid=${encodeURIComponent(searchId)}&type=${encodeURIComponent(assetType)}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IAsset[];
  }

  // Get assets for profile by profileid
  public static async getAssetsByProfileIdandType(
    profileId: string,
    assetType: string,
    maxReturned: number
  ): Promise<IAsset[]> {
    const url = `getbyprofileid?profileid=${encodeURIComponent(profileId)}&type=${encodeURIComponent(
      assetType
    )}&maxreturned=${maxReturned}`;
    const response = await (await getApi(this.apiBase)).get(url);
    return response.data as IAsset[];
  }

  public static async postCreate(payload: IAssetCreationData): Promise<IAssetCreationResult> {
    const response = await (await getApi(this.apiBase)).post('', payload);
    return response.data as IAssetCreationResult;
  }

  public static async postDelete(payload: IAssetDeletionData): Promise<IAssetDeletionResult> {
    const response = await (await getApi(this.apiBase)).delete('', {
      data: payload
    });
    return response.data as IAssetDeletionResult;
  }
}
